var flyOutButton = document.getElementById("feedback-button");
var flyOut = document.getElementById("feedback-fly-out");
var flyOutCloseButton = document.getElementById("cancel-fly-out");
if (flyOutButton && flyOut && flyOutCloseButton) {
    flyOutButton.addEventListener("click", function () {
        flyOut.classList.remove("hidden");
        flyOut.setAttribute("aria-hidden", "false");
    });
    flyOutCloseButton.addEventListener("click", function () {
        flyOut.classList.add("hidden");
        flyOut.setAttribute("aria-hidden", "true");
    });
}
