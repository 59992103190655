var menuButton = document.getElementById('menu-button');
var closeButton = document.getElementById('close-navigation');
var menu = document.getElementById('mobile-nav');
if (menuButton) {
    menuButton.addEventListener('click', function () {
        menu.classList.add('left-0');
        menu.classList.remove('-left-[999px]');
    });
}
if (closeButton) {
    closeButton.addEventListener('click', function () {
        menu.classList.add('-left-[999px]');
        menu.classList.remove('left-0');
    });
}
