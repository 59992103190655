var root = document.documentElement;
var themeSwitcher = document.getElementById('theme-switcher');
var themeMenu = document.getElementById('theme-menu');
var themeButtons = document.querySelectorAll('[data-theme-selector-value]');
// If the user clicks on the theme switcher, toggle the theme menu
if (themeSwitcher) {
    themeSwitcher.addEventListener('click', function () {
        themeMenu.classList.toggle('hidden');
    });
}
// If the user clicks on a theme button, set the theme and close the menu
if (themeButtons) {
    themeButtons.forEach(function (button) {
        button.addEventListener('click', function () {
            var theme = button.getAttribute('data-theme-selector-value');
            if (theme === 'light') {
                root.classList.remove('dark');
                root.classList.add('light');
                localStorage.setItem('theme', 'light');
            }
            else if (theme === 'dark') {
                root.classList.remove('light');
                root.classList.add('dark');
                localStorage.setItem('theme', 'dark');
            }
            themeMenu.classList.add('hidden');
        });
    });
}
if (root) {
    var isOverridden = localStorage.getItem('theme');
    // If the user has already selected a theme, we don't want to override it
    if (isOverridden) {
        root.classList.add(isOverridden);
    }
    else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        root.classList.add('dark');
    }
    else {
        root.classList.add('light');
    }
    // Only add the event listener if the user hasn't already selected a theme
    if (!isOverridden) {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function (event) {
            var newColorScheme = event.matches ? "dark" : "light";
            if (newColorScheme === 'dark') {
                root.classList.remove('light');
                root.classList.add('dark');
            }
            else if (newColorScheme === 'light') {
                root.classList.remove('dark');
                root.classList.add('light');
            }
        });
    }
}
