var inputWithOther = document.getElementById("id_question_4");
var otherInputGroup = document.getElementById("id_question_4_other");
var redirectInput = document.getElementById("redirect-after-survey");
if (inputWithOther && otherInputGroup) {
    // Check the current value at render
    if (inputWithOther.value === "18") {
        otherInputGroup.classList.remove("hidden");
        otherInputGroup.setAttribute("aria-hidden", "false");
    }
    else {
        otherInputGroup.classList.add("hidden");
        otherInputGroup.setAttribute("aria-hidden", "true");
    }
    // Add a listener to the input
    inputWithOther.addEventListener("change", function () {
        if (inputWithOther.value === "18") {
            otherInputGroup.classList.remove("hidden");
            otherInputGroup.setAttribute("aria-hidden", "false");
        }
        else {
            otherInputGroup.classList.add("hidden");
            otherInputGroup.setAttribute("aria-hidden", "true");
        }
    });
}
if (redirectInput) {
    setTimeout(function () {
        location.assign(redirectInput.value);
    }, 2000);
}
